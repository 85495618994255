import { defineStore } from "pinia";
import axios from "axios";

export const useAppsStore = defineStore({
  id: "apps",
  state: () => {
    return {
      apiEndpoint: process.env.API_ENDPOINT || "https://api.apps.hc-dev.com",
      appsLoaded: false,
      apps: [],
    };
  },

  actions: {
    async refreshApps() {
      try {
        const result = await axios.get(`${this.apiEndpoint}/apps`);
        this.apps = result.data;
      } catch (err) {
        console.error("Error loading apps from api.");
      }
      this.appsLoaded = true;
    },
  },
});


















































import { Component, Vue } from "vue-property-decorator";
const AppCardProps = Vue.extend({
  props: {
    name: String,
    version: String,
    build: String,
    releaseDate: String,
    icon: String,
    link: String,
  },
});

@Component
export default class AppCard extends AppCardProps {
  get linkIsForiOS(): boolean {
    return this.link.endsWith(".plist");
  }

  get finalLink(): string {
    return `itms-services://?action=download-manifest&url=${this.link}`;
  }

  get archivesEnabled(): boolean {
    return false;
  }

  get adminRole(): boolean {
    return false;
  }
}
